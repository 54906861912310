import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import {
  CtFormConfiguration,
  CtButtonConfiguration,
  MAT_RAISED_WARN,
  MAT_RAISED_ACCENT,
  CtControlTypes,
  CtThemeTypes,
  CTGeneralService,
  CtControlConfiguration,
  CtControlService,
  CTModelDatatableFilter,
  CtBinaryOperator,
  CtModelDatatableOperators,
  CtSelectControlOptions,
  CtSortOrderDescriptorParameter,
  CtChipsControlOptions,
  CtModelRouteData,
  CtModelConfiguration,
  CtModelService,
  CtDatatableContainerFilterValues,
  CtSelectControlValue,
  CTDatatablePaginationParameter, MateriaHintExtensionConfig, MaterialControlOptions
} from '@ctsolution/ct-framework';
import { CompanyTypeRouteData } from 'projects/ct-manager/src/app/_core/route-data/company-type.route-data';
import { ActivatedRoute } from "@angular/router";
import { CtWebapiGenericResponse, DataRequest, MethodEnum } from '@ctsolution/ct-webapi';
import { CTMGeneralService } from 'projects/ct-manager/src/app/_core/lib/general.service';
import { ContactInformationTypeVO, LeadInformationComponent } from '../lead-information/lead-information.component';
import { CtManagerService } from 'projects/ct-manager/src/app/_core/lib/ct-manager.service';
import { CTConfigurationModel } from '../../../company/company-edit/company-edit.component';

@Component({
  selector: 'app-lead-edit-form',
  templateUrl: './lead-edit-form.component.html',
  styleUrls: ['./lead-edit-form.component.scss']
})
export class LeadEditFormComponent implements AfterViewInit {

  @Output() onSubmit: EventEmitter<FormGroup | null> = new EventEmitter<FormGroup | null>();
  @Output() valueChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() masterDetail = false;
  @Input() leadOid: number | null = null;
  @Input() datatableContainerFilterValues: CtDatatableContainerFilterValues | null = null;
  @ViewChild(LeadInformationComponent) leadInformationComponent: LeadInformationComponent | undefined;

  companyOid: number | null = null;
  configuration: CtFormConfiguration;
  leadStatusComplete = false;

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.cancel")
    .setAction(() => this.CTGeneralService.back())
    .setMatherialOptions(MAT_RAISED_WARN);

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.save")
    .setClass('ct-model-submit-button')
    .setAction(() => this.onSubmit.emit(this.configuration.form))
    .setMatherialOptions(MAT_RAISED_ACCENT);

  private nameControl = this.generalService.getTextControl({
    name: 'Name',
    required: true,
  });

  private surnameControl = this.generalService.getTextControl({
    name: 'Surname',
    required: true,
  });

  private phoneControl = this.generalService.getTextControl({
    name: 'Phone',
    required: false,
  })

  private emailControl = this.generalService.getTextControl({
    name: 'Email',
    required: false,
  });

  private noteControl = this.generalService.getTextControl({
    name: 'Note',
    required: false,
  }).setFlexSpacePercentage(100)
    .setType(CtControlTypes.TEXTAREA);

  private codeControl = this.generalService.getTextControl({
    name: 'Code',
    required: false,
  }).setFlexSpacePercentage(50).setHidden(true);

  private documentDateControl = this.generalService.getDateControlDefault({
    name: 'DocumentDate',
    required: true,
  }).setFlexSpacePercentage(50)
    .setValue(new Date());

  private VatControl = this.generalService.getTextControl({
    name: 'VATNUMBER',
    required: false,
  }).setFlexSpacePercentage(50);

  private SDIControl = this.generalService.getTextControl({
    name: 'SDICODE',
    required: false,
  }).setFlexSpacePercentage(100);

  private leadTypeControl = this.generalService.getLookupControlDefaultConfiguration({
    name: 'LeadType',
    controller: 'LeadType',
    type: CtControlTypes.ENUMERABLE
  }).setFlexSpacePercentage(50)

  private leadSourceControl = this.generalService.getLookupControlDefaultConfiguration({
    name: 'LeadSource',
    controller: 'LeadSource',
    type: CtControlTypes.ENUMERABLE,
    required: false
  }).setFlexSpacePercentage(33.33);

  private leadStatusControl = this.generalService.getLookupControlDefaultConfiguration({
    name: 'LeadStatus',
    controller: 'LeadStatus',
    type: CtControlTypes.ENUMERABLE,
    key : 'OrderIndex'
  }).setFlexSpacePercentage(33.33)

  private leadInterestControl = this.generalService.getLookupControlDefaultConfiguration({
    name: 'LeadInterest',
    controller: 'LeadInterest',
    type: CtControlTypes.ENUMERABLE,
    required: false
  }).setFlexSpacePercentage(33.33);

  // private customerControl = this.generalService.getLookupControlDefaultConfiguration({
  //   name: 'Customer',
  //   controller: 'Company',
  //   type: CtControlTypes.LOOKUP,
  //   required: false,
  //   action: 'LookupForCustomers',
  // }).setNullValueEnabled(true)
  // .setLabel('CustomerCompiled')

  private customerControl: CtControlConfiguration =
    this.controlService.getDefaultConfiguration({
      name: 'Customer',
      label: 'CustomerCompiled',
      type: CtControlTypes.LOOKUP,
      remoteValueOptionsEndpoint: DataRequest
        .create()
        .setController(['Company'])
        .setAction('LookupForCustomers')
        .setMethod(MethodEnum.POST)
        .setQueryParams({ hideSpinner: true })
    })
      .setNullValueEnabled(true)
      .setTheme(CtThemeTypes.MATERIAL)
      .configureOptions<CtSelectControlOptions>(options => {

        if (!options) options = CtSelectControlOptions.create();

        options
          .setNullValueEnabled(false)
          .setLookupFilter((value) => {

            const filters: CTModelDatatableFilter[] = [];

            if (value) {

              filters
                .push(
                  CTModelDatatableFilter
                    .create()
                    .setField("[FiscalCode] is not null AND Contains([FiscalCode], ?)")
                    .setValue([value])
                    .setOperatorType(CtBinaryOperator.OrOperator),
                  CTModelDatatableFilter
                    .create()
                    .setField("[Name] is not null AND Contains([Name], ?)")
                    .setValue([value])
                    .setOperatorType(CtBinaryOperator.OrOperator),
                  CTModelDatatableFilter
                    .create()
                    .setField("[VAT] is not null AND Contains([VAT], ?)")
                    .setValue([value])
                    .setOperatorType(CtBinaryOperator.OrOperator),
                  CTModelDatatableFilter
                    .create()
                    .setField("[Surname] is not null AND Contains([Surname], ?)")
                    .setValue([value])
                    .setOperatorType(CtBinaryOperator.OrOperator),
                  CTModelDatatableFilter
                    .create()
                    .setField("[ReferenceName] is not null AND Contains([ReferenceName], ?)")
                    .setValue([value])
                    .setOperatorType(CtBinaryOperator.OrOperator)
                );

            }

            const operators = CtModelDatatableOperators
              .create()
              .setFilters(filters);

            operators
              .Pagination
              ?.setSortOrders(new Array<CtSortOrderDescriptorParameter>(CtSortOrderDescriptorParameter.create('Name')))

            return operators;

          })

      })


  private customerTypeControl = this.generalService.getLookupControlDefaultConfiguration({
    name: 'CustomerType',
    controller: 'CompanyType',
    type: CtControlTypes.ENUMERABLE
  })

  private companyPlaceControl: CtControlConfiguration =
    this.controlService.getDefaultConfiguration({
      name: 'CompanyPlace',
      label: 'CompanyPlace',
      type: CtControlTypes.ENUMERABLE
    })
      .setTheme(CtThemeTypes.MATERIAL)
      .setFlexSpacePercentage(50);

  private tagControl: CtControlConfiguration = CtControlConfiguration
    .create()
    .setName('Tags')
    .setLabel('TAG')
    .setType(CtControlTypes.CHIPS)
    .setTheme(CtThemeTypes.MATERIAL)
    .setRemoteValueOptionsEndpoint(
      DataRequest
        .create()
        .setUrl(`/Tag/Lookup`)
        .setMethod(MethodEnum.POST)
        .setBody(CtModelDatatableOperators.create()))
    .configureOptions<CtChipsControlOptions>(options => {

      if (!options) options = CtChipsControlOptions.create();

      options
        .setOnAdd((event: string) => new Promise<CtWebapiGenericResponse<number | string> | null>((resolve) => {

          const controller = 'tag';

          const routeData = CtModelRouteData
            .create()
            .setController(controller);

          const configuration = CtModelConfiguration
            .create()
            .setRouteData(routeData)

          this.modelService
            .putInfos(configuration, { content: event })
            ?.subscribe((response: CtWebapiGenericResponse<any>) => resolve(response.Result))

        }));


    }).setFlexSpacePercentage(50);

  private customerType: CustomerTypeEnum = CustomerTypeEnum.PRIVATE;
  private company: any = null;


  private controls = [
    this.documentDateControl,
    this.leadTypeControl,
    this.customerControl,
    this.customerTypeControl,
    this.nameControl,
    this.surnameControl,
    this.phoneControl,
    this.emailControl,
    this.VatControl,
    this.SDIControl,
    this.leadSourceControl,
    this.leadStatusControl,
    this.leadInterestControl,
    this.companyPlaceControl,
    this.tagControl,
    this.noteControl,
    this.codeControl
  ];


  constructor(
    private generalService: CTMGeneralService,
    private CTGeneralService: CTGeneralService,
    private formBuilder: FormBuilder,
    private controlService: CtControlService,
    private activatedRoute: ActivatedRoute,
    private modelService: CtModelService<any>,
    private route: ActivatedRoute,
    private managerService : CtManagerService) {

    this.configuration = CtFormConfiguration
      .create(this.formBuilder.group({}))
      .setTheme(CtThemeTypes.MATERIAL)
      .setControls(this.controls.filter(control => control !== this.VatControl && control !== this.SDIControl));

    this.setCustomerType(null);

  }

  ngOnInit(): void {

    this.setupStatusControlCustomization();
    this.getSelfCompany();

  }

  private setupStatusControlCustomization() {

    const materialOptions = this.leadStatusControl.materialOptions;

    if (!materialOptions) {

      this.leadStatusControl.setMaterialOptions(MaterialControlOptions.create());

    }

    this.leadStatusControl.materialOptions
      ?.setHint(MateriaHintExtensionConfig.create().setMessage('leadStatusControlHint'));

  }

  private getSelfCompany() {

    const filters: CTModelDatatableFilter[] = [
      CTModelDatatableFilter
        .create()
        .setField('IsSelf')
        .setValue(true)
        .setOperatorType(CtBinaryOperator.Equal),
    ];

    const pagination = new CTDatatablePaginationParameter(1, 100);

    this.generalService.getList({
      controller: "Company",
      filters: filters,
      pagination: pagination,
    }).subscribe((response: CtWebapiGenericResponse<any>) => {

      if (!response.Result.DataSource) return;

      const result = response.Result.DataSource;

      const options: CtSelectControlValue[] = [];

      const firstResult = result[0];
      const referenceName = firstResult.ReferenceName ?? '';

      firstResult.Places.forEach((place: { Name: string; Oid: any; City: string | null; }) => {
        const placeName = place.Name ?? '';
        const cityName = place.City ?? '';

        const name = `${referenceName} - ${placeName}, ${cityName}`;
        options.push(CtSelectControlValue.create().setLabel(name).setValue(place.Oid));
      });

      this.companyPlaceControl.setValueOptions(options);

    })

  }

  ngAfterViewInit() {

    this.customerControl
      .control
      ?.valueChanges
      .subscribe(result => {

        if (result == null || result.value == null) {

          this.company = null;
          this.setController(this.company, this.customerType);
          this.companyOid = null;
          this.leadInformationComponent?.reset();
          this.valueChanged.emit(true);

          return

        }


        this.valueChanged.emit(false);

        this.generalService.getInfos({
          controller: "Company",
          oid: result.value
        }).subscribe((response: CtWebapiGenericResponse<any>) => {

          this.company = response.Result.DataSource;

          if (this.company) {

            this.companyOid = this.company.Oid;
            this.leadInformationComponent?.setup(this.companyOid);
            this.customerTypeControl.setValue(this.company.Type.Oid);
            this.customerType = this.company.Type.Type;
            this.setController(this.company, this.customerType)

          }

        })

      })

    this.customerTypeControl.control?.valueChanges.subscribe(value => {

      this.setCustomerType(value);

    })

    // const codeLeadType = this.getValueFilterCodeLead();

    // if (codeLeadType) {
    //   this.setupLeadTypeControlByQueryString(codeLeadType);
    // }

    const typeCustomerType = this.getValueFilterTypeCustomer();

    if (typeCustomerType) {
      this.setupCustomerTypeControlByQueryString(typeCustomerType);
    }

    // this.setLeadStatusDefault();

  }

  private setLeadStatusDefault() {


    const pId: string | null = this.route.snapshot.paramMap.get("id");

    if (this.leadStatusControl.value || pId) return;

    const filters: CTModelDatatableFilter[] = [];

    const pagination = new CTDatatablePaginationParameter(1, 100);


    this.generalService.getList({
      controller: "LeadStatus",
      filters: filters,
      pagination: pagination,
    }).subscribe((response: CtWebapiGenericResponse<any>) => {

      const result = response.Result.DataSource;
      this.leadStatusControl.setValue(result.find((x: { Type: number; }) => x.Type == LeadStatusEnum.NUOVO).Oid);

    })


  }

  private setCustomerType(CustomerTypeOid: number | null) {

    const configuration: CtModelConfiguration<any> = CtModelConfiguration
      .create()
      .setRouteData(CompanyTypeRouteData())

    const operators: CtModelDatatableOperators = CtModelDatatableOperators
      .create();

    if (CustomerTypeOid) {

      operators.setFilters([
        CTModelDatatableFilter
          .create()
          .setField("Oid")
          .setValue(CustomerTypeOid)
          .setOperatorType(CtBinaryOperator.Equal)
      ])
    }


    this.modelService.getList(configuration, operators)
      .subscribe(async (response: CtWebapiGenericResponse<any>) => {

        if (CustomerTypeOid == null) {

          const cmpnType : CTConfigurationModel = CTConfigurationModel.create().setType(CustomerTypeEnum.PRIVATE)

        await this.managerService.getComponentOverride("lead", "companyType", cmpnType).then((component) => {
            return;
          });

          response.Result.DataSource.forEach((element: { Type: CustomerTypeEnum; Oid: any; }) => {

            if (element.Type == cmpnType.type) {

              this.customerTypeControl.setValue(element.Oid)
              this.customerType = element.Type

            }

          })

        }

        const result = response.Result.DataSource[0];
        this.customerType = result.Type
        this.setController(this.company, this.customerType);

      })


  }

  setupLeadTypeControlByQueryString(code: string) {

    this.leadTypeControl.configureOptions<CtSelectControlOptions>(options => {

      if (!options) options = CtSelectControlOptions.create();

      options
        .setNullValueEnabled(false)
        .setLookupFilter(() => {

          const operators = CtModelDatatableOperators
            .create()

          operators
            .Pagination
            ?.setSortOrders(new Array<CtSortOrderDescriptorParameter>(CtSortOrderDescriptorParameter.create('Name')))

          const filters: CTModelDatatableFilter[] = [
            CTModelDatatableFilter
              .create()
              .setField('Code')
              .setValue(code)
              .setOperatorType(CtBinaryOperator.Equal),
          ];

          operators
            .setFilters(filters);

          return operators

        })
        ?.setLookupResponseMapper(
          (response: CtWebapiGenericResponse<CtModelConfiguration<any>>) => {

            const result = (<Array<any>>response.Result.DataSource ?? []);

            if (result.length) {

              const toReturn = result.map(elm => CtSelectControlValue.create().setLabel(elm.Description).setValue(elm));


              const firstOrDefault = toReturn[0];
              this.leadTypeControl?.setValue(firstOrDefault.value)

              return toReturn;

            }

            return [];
          })

    });

    (<CtSelectControlOptions>this.leadTypeControl.options).notifyValuesUpdated();
  }
  setupCustomerTypeControlByQueryString(type: string) {

    this.customerTypeControl.configureOptions<CtSelectControlOptions>(options => {

      if (!options) options = CtSelectControlOptions.create();

      options
        .setNullValueEnabled(false)
        .setLookupFilter(() => {

          const operators = CtModelDatatableOperators
            .create()

          operators
            .Pagination
            ?.setSortOrders(new Array<CtSortOrderDescriptorParameter>(CtSortOrderDescriptorParameter.create('Name')))

          const filters: CTModelDatatableFilter[] = [
            CTModelDatatableFilter
              .create()
              .setField('Type')
              .setValue(type)
              .setOperatorType(CtBinaryOperator.Equal),
          ];

          operators
            .setFilters(filters);

          return operators

        })
        ?.setLookupResponseMapper(
          (response: CtWebapiGenericResponse<CtModelConfiguration<any>>) => {

            const result = (<Array<any>>response.Result.DataSource ?? []);

            if (result.length) {

              const toReturn = result.map(elm => CtSelectControlValue.create().setLabel(elm.Description).setValue(elm.Code));


              const firstOrDefault = toReturn[0];

              this.customerTypeControl?.setValue(firstOrDefault.value)

              return toReturn;

            }

            return [];
          })

    });

    this.customerControl.configureOptions<CtSelectControlOptions>(options => {

      if (!options) options = CtSelectControlOptions.create();

      options
        .setNullValueEnabled(false)
        .setLookupFilter((value : any) => {

          const operators = CtModelDatatableOperators
            .create()

          operators
            .Pagination
            ?.setSortOrders(new Array<CtSortOrderDescriptorParameter>(CtSortOrderDescriptorParameter.create('Name')))

          const filters: CTModelDatatableFilter[] = [
            CTModelDatatableFilter
              .create()
              .setField('Type.Type')
              .setValue(type)
              .setOperatorType(CtBinaryOperator.Equal),
          ];

          if (value) {

            filters
              .push(
                CTModelDatatableFilter
                  .create()
                  .setField("[FiscalCode] is not null AND Contains([FiscalCode], ?)")
                  .setValue([value])
                  .setOperatorType(CtBinaryOperator.OrOperator),
                CTModelDatatableFilter
                  .create()
                  .setField("[Name] is not null AND Contains([Name], ?)")
                  .setValue([value])
                  .setOperatorType(CtBinaryOperator.OrOperator),
                CTModelDatatableFilter
                  .create()
                  .setField("[VAT] is not null AND Contains([VAT], ?)")
                  .setValue([value])
                  .setOperatorType(CtBinaryOperator.OrOperator),
                CTModelDatatableFilter
                  .create()
                  .setField("[Surname] is not null AND Contains([Surname], ?)")
                  .setValue([value])
                  .setOperatorType(CtBinaryOperator.OrOperator),
                CTModelDatatableFilter
                  .create()
                  .setField("[ReferenceName] is not null AND Contains([ReferenceName], ?)")
                  .setValue([value])
                  .setOperatorType(CtBinaryOperator.OrOperator)
              );

          }

          operators
            .setFilters(filters);

          return operators

        })
        ?.setLookupResponseMapper(
          (response: CtWebapiGenericResponse<CtModelConfiguration<any>>) => {

            const result = (<Array<any>>response.Result.DataSource ?? []);

            if (result.length) {

              const toReturn = result.map(elm => CtSelectControlValue.create().setLabel(elm.Description).setValue(elm.Code));

              return toReturn;

            }

            return [];
          })

    });

    this.setLeadSourceFilter(+type);


    (<CtSelectControlOptions>this.customerTypeControl.options).notifyValuesUpdated();
    (<CtSelectControlOptions>this.customerControl.options).notifyValuesUpdated();

  }


  getValueFilterCodeLead(): string | null {

    let value: string | null = null;

    if (this.datatableContainerFilterValues) {

      value = this.datatableContainerFilterValues.constants.find((item) => item.Field === 'LeadType.Code')?.Value

    }

    return value

  }

  getValueFilterTypeCustomer(): string | null {

    let value: string | null = null;

    if (this.datatableContainerFilterValues) {

      value = this.datatableContainerFilterValues.constants.find((item) => item.Field?.includes('CustomerType.Type'))?.Value[0]
      //Do per scontato che venga filtrato solo per un code

      if (!value) value = this.datatableContainerFilterValues.constants.find((item) => item.Field?.includes('Customer.Type.Type'))?.Value[0]
      //Do per scontato che venga filtrato solo per un code


    }

    return value

  }


  private setLeadCompanyControl(customerType: CustomerTypeEnum | null = null) {

    if (!this.configuration.controls) return;

    this.setDisabledControls(true);
    this.setValueControls(customerType);

    this.configuration.form?.updateValueAndValidity();

  }

  private setDisabledControls(value: boolean) {

    this.nameControl.setDisabled(value);
    this.surnameControl.setDisabled(value);
    this.phoneControl.setDisabled(value);
    this.emailControl.setDisabled(value);
    this.VatControl.setDisabled(value);
    this.SDIControl.setDisabled(value);

    if (value == false) {

      // this.nameControl.setValue(null);
      // this.surnameControl.setValue(null);
      // this.phoneControl.setValue(null);
      // this.emailControl.setValue(null);
      // this.VatControl.setValue(null);
      // this.SDIControl.setValue(null);
      //TODO: aggiustare logica

      this.customerTypeControl.control?.enable({ emitEvent: false })

    } else {

      this.customerTypeControl.control?.disable({ emitEvent: false })


    }

  }

  private setValueControls(customerType: CustomerTypeEnum | null = null) {

    this.nameControl.setValue(this.company?.Name)

    this.generalService.getList(
      {
        controller: "CompanyInformation",
        filters: [
          CTModelDatatableFilter
            .create()
            .setField("Company.Oid")
            .setValue(this.company.Oid.toString())
            .setOperatorType(CtBinaryOperator.Equal)
        ],
        pagination: new CTDatatablePaginationParameter(1, 100)
      }
    ).subscribe((response: CtWebapiGenericResponse<any>) => {

      const information = response.Result.DataSource;

      information.forEach((element: any) => {


        if (element.Type == ContactInformationTypeVO.FirstEmail)
          this.emailControl.setValue(element.Content)

        if (element.Type == ContactInformationTypeVO.FirstPhone)
          this.phoneControl.setValue(element.Content)

        if (element.Type == ContactInformationTypeVO.SDI)
          this.SDIControl.setValue(element.Content)

      });


    })

    if (CustomerTypeEnum.PRIVATE == customerType) {

      this.nameControl.setLabel('Name')
      this.tagControl.setLabel("TagPrivate")
      this.surnameControl.setValue(this.company?.Surname)

      this.configuration = CtFormConfiguration
        .create(this.formBuilder.group({}))
        .setTheme(CtThemeTypes.MATERIAL)
        .setControls(this.controls.filter(control => control !== this.VatControl && control !== this.SDIControl));

    } else {

      this.nameControl.setLabel('CompanyName')
      this.tagControl.setLabel("TagBusiness")
      this.nameControl.setValue(this.company?.ReferenceName)
      this.VatControl.setValue(this.company?.VAT)
      this.SDIControl.setValue(this.company?.SDI)

      this.configuration = CtFormConfiguration
        .create(this.formBuilder.group({}))
        .setTheme(CtThemeTypes.MATERIAL)
        .setControls(this.controls.filter(control => control !== this.surnameControl));

    }

  }

  private unSetLeadCompanyControl(customerType: CustomerTypeEnum | null = null) {

    if (!this.configuration.controls) return;

    this.setDisabledControls(false);

    this.customerControl.setFlexSpacePercentage(50);

    if (CustomerTypeEnum.PRIVATE == customerType) {

      this.nameControl.setLabel('Name')
      this.tagControl.setLabel("TagPrivate")

      this.configuration = CtFormConfiguration
        .create(this.formBuilder.group({}))
        .setTheme(CtThemeTypes.MATERIAL)
        .setControls(this.controls.filter(control => control !== this.VatControl && control !== this.SDIControl));

    } else {

      this.nameControl.setLabel('CompanyName')
      this.tagControl.setLabel("TagBusiness")

      this.configuration = CtFormConfiguration
        .create(this.formBuilder.group({}))
        .setTheme(CtThemeTypes.MATERIAL)
        .setControls(this.controls.filter(control => control !== this.surnameControl));

    }
    if (customerType)
      this.setLeadSourceFilter(customerType);

    this.configuration.form?.updateValueAndValidity();

  }

  private setController(company: any = null, customerType: CustomerTypeEnum | null = null) {

    if (company) {

      this.setLeadCompanyControl(customerType);

    } else {

      this.unSetLeadCompanyControl(customerType);

    }

  }

  private setLeadSourceFilter(customerType: CustomerTypeEnum) {


    //Prima modificava il LeadStatusControl, dato che non esiste più quella logica l'ho sostituita con il LeadSourceControl

    this.leadSourceControl
      .configureOptions<CtSelectControlOptions>(options => {

        if (!options) options = CtSelectControlOptions.create();

        options
          .setNullValueEnabled(false)
          .setLookupFilter(() => {

            const operators = CtModelDatatableOperators
              .create()

            const filters: CTModelDatatableFilter[] = [
              CTModelDatatableFilter
                .create()
                .setField('[LeadSourceType] is null OR [LeadSourceType.CompanyType.Type] = ?')
                .setValue([customerType])
                .setOperatorType(CtBinaryOperator.OrOperator),
            ];

            operators
              .setFilters(filters);

            return operators

          })
          ?.setLookupResponseMapper(
            (response: CtWebapiGenericResponse<CtModelConfiguration<any>>) => {

              const result = (<Array<any>>response.Result.DataSource ?? []);

              if (result.length) {

                const toReturn = result.map(elm => CtSelectControlValue.create().setLabel(elm.Description).setValue(elm.Code));

                return toReturn;

              }

              return [];
            })

      });

    (<CtSelectControlOptions>this.leadSourceControl.options).notifyValuesUpdated();

  }

  setup<T>(value: T | null) {

    if (!value) return;

    const form: any = value;

    const formValue: any = {
      DocumentDate: form.DocumentDate,
      Email: form.Email ?? null,
      LeadInterest: form.LeadInterest?.Oid,
      LeadSource: form.LeadSource?.Oid,
      LeadStatus: form.LeadStatus.Oid,
      Name: form.Name ?? null,
      Phone: form.Phone ?? null,
      Surname: form.Surname ?? null,
      CompanyPlace: form.CompanyPlace?.Oid,
      Code: form.Code ?? null,
      Note: form.Note ?? null

    }

    if (form.Customer) {

      this.customerControl
        .setValue(
          CtSelectControlValue
            .create()
            .setLabel(form.Customer.ReferenceName)
            .setValue(form.Customer.Oid))

    }

    this.configuration.form?.patchValue(formValue);
    this.surnameControl.setValue(formValue.Surname);
    this.SDIControl.setValue(form.SdiCode ?? null);
    this.VatControl.setValue(form.VatNumber ?? null);


    const code = this.configuration.form?.value?.Code;

    if (code) {

      this.activatedRoute
        .data
        .subscribe(data => data['extraString'] = `(Codice ${code})`);

    }

    this.customerTypeControl.setValue(form?.CustomerType?.Oid ?? null)
    this.leadStatusControl.setValue(form?.LeadStatus.Oid ?? null);
    this.leadTypeControl.setValue(form.LeadType.Oid ?? null);
    this.checkLeadStatus(this.leadStatusControl.value);


    if (form.Tags?.length) {

      const tagsValue: CtSelectControlValue[] = [];

      form.Tags.forEach((tag: { Content: string | null; Oid: any; }) => {

        tagsValue.push(CtSelectControlValue.create().setLabel(tag.Content).setValue(tag.Oid))
      });

      setTimeout(() => {
        (<CtChipsControlOptions>this.tagControl.options)?.notifyValuesUpdated(tagsValue);

      }, 1000);

    }

  }

  private checkLeadStatus(oidStatus: number): void {

    this.generalService.getList({

      controller: "LeadStatus"

    }).subscribe((response: CtWebapiGenericResponse<any>) => {

      if (!response.Result.DataSource) return;

      if (response.Result.DataSource.find((el: { Oid: number; }) => el.Oid == oidStatus).Type == 4)
        //TODO: creare enum
        this.leadStatusComplete = true;

      else

        this.leadStatusComplete = false;

    })

  }


}

export enum CustomerTypeEnum {

  BUSINESS = 2,
  PRIVATE = 1,
  DEFAULT = 0

}  //TODO: Sostituire con companyType e spostare su enum

export enum LeadStatusEnum {
  NUOVO = 1,
  IN_GESTIONE = 2,
  CHIUSO = 3,
  COMPLETATO = 4,
  FALLITO = 5,
  CANCELLATO = 6
}
