<ng-container *ngIf="pId; else errorTemplate">

  <mat-tab-group>

    <mat-tab [label]="'CT_MENU.item' | translate">

      <app-module-items [referenceId]="pId"></app-module-items>

    </mat-tab>

    <mat-tab [label]="'CT_MENU.locationstock' | translate">

      <app-module-stocks [referenceId]="pId"></app-module-stocks>

    </mat-tab>

    <mat-tab
      [label]="'Order&Handlings' | translate">

      <app-module-handlings [referenceId]="pId"></app-module-handlings>

    </mat-tab>

  </mat-tab-group>

</ng-container>
<ng-template #headerActions>

  <ct-button *ngIf="moduleItemsComponent?.moduleHasItems" [configuration]="createButton"></ct-button>

</ng-template>
<ng-template #errorTemplate>

  <p> An error occurred. If this issue persists please contact us through our help center at info@ctsolution.it</p>

</ng-template>
