<ng-container>

  <div class="module-item-order-container">

    <mat-list>

      <div *ngFor="let handling of handlings; let last = last;">

        <div mat-subheader *ngIf="getSourceFrom(handling)"> {{ 'LocationFrom' | translate }} {{ getSourceFrom(handling) ?? 'unknown' }}</div>
        <!-- TODO: controllare perché arriva unknown e non trova la location -->

        <div mat-subheader *ngIf="handling.InternalNotes"> {{handling.InternalNotes}}</div>

        <mat-list-item *ngFor="let itemHandling of handling.ItemHandlings">

          <mat-icon matListItemIcon>{{ getSourceIcon(handling) }}</mat-icon>
          <div mat-line><strong> {{ itemHandling.Item?.Code }}</strong> {{ itemHandling.Item?.Name }}</div>
          <div mat-line>{{ itemHandling.StartQty }} {{ itemHandling.UM?.Symbol }}</div>

        </mat-list-item>

        <mat-divider *ngIf="!last"></mat-divider>

      </div>

    </mat-list>

  </div>

</ng-container>


<ng-container *ngIf="this.model">

  <div class="module-item-order-container" *ngIf="model.ItemSuppliers">

    <mat-list style="padding-bottom: 20px;">
      <strong>Seleziona i fornitori.</strong><br>
      <div mat-subheader >Se non viene selezionato nessun fornitore verrà utilizzato il primo disponibile.</div>

      <mat-list-item style="height: 72px;" *ngFor="let item of model.ItemSuppliers">

        <div mat-line style="display: flex; justify-content: space-between; align-items: center;"><span style="white-space: pre-line;"><strong> {{ item.ItemCode }}<br></strong> {{ item.ItemName }}</span>
          <div>
            <mat-form-field *ngIf="item.Suppliers">
              <mat-select (selectionChange)="onSupplierChange(item, $event.value)" placeholder="Seleziona fornitore" [hideSingleSelectionIndicator]="true">
                <mat-option style="background-color: white;" *ngFor="let supplier of item.Suppliers" [value]="supplier" >
                  {{ supplier.ReferenceName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

      </mat-list-item>

    </mat-list>

  </div>

</ng-container>
