import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CtBinaryOperator, CtButtonConfiguration, CtControlConfiguration, CtControlService, CtControlTypes, CtControlValidator, CTFieldInfos, CtFormConfiguration, CtFormContainerHelper, CTGeneralService, CtModelConfiguration, CTModelDatatableFilter, CtModelDatatableOperators, CtSelectControlOptions, CtSelectControlValue, CtSortOrderDescriptorParameter, CtThemeTypes, MAT_RAISED_PRIMARY, MAT_RAISED_WARN } from '@ctsolution/ct-framework';
import { CTMGeneralService } from '../../../_core/lib/general.service';
import { FormBuilder } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import { WorkOrderPaymentRouteData } from '../../../_core/route-data/work-order-payment.route-data';
import { environment } from 'projects/ct-manager/src/environments/environment';
import { PaymentsEditConfiguration } from './payments-edit.configuration';
import { OriginPaymentEnum, WorkOrderPayment } from '../../../_core/classes/work-order-payments';

@Component({
  selector: 'app-payments-edit',
  templateUrl: './payments-edit.component.html',
  styleUrls: ['./payments-edit.component.scss']
})
export class PaymentsEditComponent {

  formConfiguration: CtFormConfiguration | null = null;

  private AmountControl = this.ctControlService.getDefaultConfiguration({
    name: 'Amount',
    label: 'AmountEuro',
    type: CtControlTypes.NUMBER,
    required: false
  })
    .setTheme(CtThemeTypes.MATERIAL)
    .setFlexSpacePercentage(50)

  private DescriptionControl = this.ctControlService.getDefaultConfiguration({
    name: 'Description',
    label: 'informations',
    type: CtControlTypes.TEXT,
    required: false
  })
    .setTheme(CtThemeTypes.MATERIAL)
    .setFlexSpacePercentage(100)

  private NotesControl = this.ctControlService.getDefaultConfiguration({
    name: 'Notes',
    label: 'Notes',
    type: CtControlTypes.TEXTAREA
  })
    .setTheme(CtThemeTypes.MATERIAL)
    .setFlexSpacePercentage(100);

  private PaymentDateControl = this.ctControlService.getDefaultConfiguration({
    name: 'PaymentDate',
    label: 'PaymentDate',
    type: CtControlTypes.DATE,
    required: false
  })
    .setTheme(CtThemeTypes.MATERIAL)
    .setFlexSpacePercentage(50)
    .setNullValueEnabled(true);

  private CompanyControl = this.generalService.getLookupControlDefaultConfiguration({
    name: 'Company',
    controller: 'Company',
    type: CtControlTypes.ENUMERABLE,
    required: false
  })
    .setLabel("Azienda")
    .configureOptions<CtSelectControlOptions>(options => {

      if (!options) options = CtSelectControlOptions.create();

      options
        ?.setNullValueEnabled(true)

      options
        ?.setLookupFilter(() => {

          const filters: CTModelDatatableFilter[] = [
            CTModelDatatableFilter
              .create()
              .setField('IsSelf')
              .setValue(true)
              .setOperatorType(CtBinaryOperator.Equal),
          ];

          const operators = CtModelDatatableOperators
            .create()
            .setFilters(filters);

          operators
            .Pagination
            ?.setSortOrders(new Array<CtSortOrderDescriptorParameter>(CtSortOrderDescriptorParameter.create('Name')))

          return operators;


        })


    });


  private UserInfoFieldInfo: CTFieldInfos = CTFieldInfos.create({
    IsDTO: true,
    Field: "UserInfo",
    Type: "UserInfoDTO",
    IsNullable: false,
    ControlType: "Lookup",
    LookupInfos: {
      RemoteEndpoint: `${environment.dev.REST_API_SERVER}/User/LookupForUserAgents`,
      CodeField: "Code",
      DescriptionField: "Description",
      IncrementalSearch: true,
    },
  } as CTFieldInfos);

  private UserInfoControl: CtControlConfiguration = CtControlConfiguration
    .create(
      CtFormContainerHelper
        .create()
        .getCTFormControlByCTFieldInfo(this.UserInfoFieldInfo))
    .setTheme(CtThemeTypes.MATERIAL)
    .setFlexSpacePercentage(50)
    .setLabel('InternalReference')
    .configureOptions<CtSelectControlOptions>(options => {

      if (!options) options = CtSelectControlOptions.create();

      options
        .setNullValueEnabled(true)
        .setLookupFilter((value) => {

          const filters: CTModelDatatableFilter[] = [];

          if (value) {

            filters
              .push(
                CTModelDatatableFilter
                  .create()
                  .setField("NameSurname")
                  .setValue(value)
                  .setOperatorType(CtBinaryOperator.Contains)
              );
          }

          const operators = CtModelDatatableOperators
            .create()
            .setFilters(filters);

          operators
            .Pagination
            ?.setSortOrders(new Array<CtSortOrderDescriptorParameter>(CtSortOrderDescriptorParameter.create('Name')))

          return operators;

        })

    });

  private StatusControl = this.generalService.getLookupControlDefaultConfiguration({
    name: 'Status',
    controller: 'WorkOrderPaymentStatus',
    type: CtControlTypes.ENUMERABLE,
    required: false
  })
    .configureOptions<CtSelectControlOptions>(options => {

      if (!options) options = CtSelectControlOptions.create();

      options
        ?.setNullValueEnabled(false)

    });

  private ContactControl = this.generalService
    .getLookupControlDefaultConfiguration({
      name: 'Contact',
      controller: 'Contact',
      key: 'Name',
      action: 'Lookup',
      required: false,
    })
    .setLabel('Amministratore')
    .configureOptions<CtSelectControlOptions>(options => {

      if (!options) options = CtSelectControlOptions.create();

      options
        .setNullValueEnabled(true)
        .setLookupFilter((value) => {

          const filters: CTModelDatatableFilter[] = [];

          if (value) {

            filters
              .push(
                CTModelDatatableFilter
                  .create()
                  .setField("[Name] is not null AND Contains([Name], ?)")
                  .setValue([value])
                  .setOperatorType(CtBinaryOperator.OrOperator),
                CTModelDatatableFilter
                  .create()
                  .setField("[Surname] is not null AND Contains([Surname], ?)")
                  .setValue([value])
                  .setOperatorType(CtBinaryOperator.OrOperator),
              );
          }

          const operators = CtModelDatatableOperators
            .create()
            .setFilters(filters);

          operators
            .Pagination
            ?.setSortOrders(new Array<CtSortOrderDescriptorParameter>(CtSortOrderDescriptorParameter.create('Name')))

          return operators;

        })

    });


  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.cancel")
    .setAction(() => this.close())
    .setMatherialOptions(MAT_RAISED_WARN);

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("CT_PAGE.CT_FORM.save")
    .setClass('ct-model-submit-button')
    .setAction(() => this.submit())
    .setMatherialOptions(MAT_RAISED_PRIMARY);


  workOrderOid: number | null = null;


  private controls = [
    this.DescriptionControl,
    this.AmountControl,
    this.PaymentDateControl,
    this.StatusControl,
    this.CompanyControl,
    this.UserInfoControl,
    this.ContactControl,
    this.NotesControl
  ]

  constructor(private cdr: ChangeDetectorRef, private dialogRef: MatDialogRef<PaymentsEditComponent> | null, private route: ActivatedRoute, @Inject(MAT_DIALOG_DATA) public parameter: PaymentsEditConfiguration | null, private generalService: CTMGeneralService, private ctControlService: CtControlService, private formBuilder: FormBuilder, private Router: Router) {

    this.formConfiguration = CtFormConfiguration
      .create(this.formBuilder.group({}))
      .setTheme(CtThemeTypes.MATERIAL)
      .setControls(this.controls);

  }

  ngAfterViewInit() {

    this.setup();

  }

  setup() {


    const pId = this.route.snapshot.paramMap.get("id");
    const id = pId ?? this.parameter?.Oid;

    if (id == null) return;

    this.generalService.getInfos(
      {
        controller: 'WorkOrderPayment',
        oid: +id
      }
    ).subscribe(result => {

      if (result.Result) {
        this.setupForm(result.Result.DataSource)
        if (result.Result.DataSource.WorkOrder?.Oid)
          this.workOrderOid = result.Result.DataSource.WorkOrder.Oid;

      }


    })

  }


  setupForm(model: any) {

    this.formConfiguration?.form?.patchValue(model);

    if (model.Status) {

      this.StatusControl.setValue(model.Status.Oid)

    }

    if (model.UserInfo) {

      this.UserInfoControl.setValue(CtSelectControlValue.create().setValue(model.UserInfo.Oid).setLabel(model.UserInfo.NameSurname))

    }

    if (model.Company) {

      this.CompanyControl.setValue(model.Company.Oid)

    }

    if (model.Contact) {

      this.ContactControl.setValue(CtSelectControlValue.create().setValue(model.Contact.Oid).setLabel(model.Contact.LookupInfos))

    }

    this.cdr.detectChanges();

  }



  async submit() {

    this.formConfiguration?.form?.markAllAsTouched();

    if (this.formConfiguration?.form?.valid) {

      const data: WorkOrderPayment = WorkOrderPayment.create(this.formConfiguration?.form.value)

      if (this.parameter?.WorkOrderOid || this.workOrderOid)
        data.setWorkOrderOid(this.parameter?.WorkOrderOid ? this.parameter?.WorkOrderOid : this.workOrderOid)

      if (this.formConfiguration.form.get('Status')?.value)
        data.setStatusOid(this.formConfiguration.form.get('Status')?.value)

      if (this.formConfiguration.form.get('Company')?.value)
        data.setCompanyOid(this.formConfiguration.form.get('Company')?.value)

      if (this.formConfiguration.form.get('UserInfo')?.value)
        data.setUserInfoOid(this.formConfiguration.form.get('UserInfo')?.value.value)

      if (this.formConfiguration.form.get('Contact')?.value)
        data.setContactOid(this.formConfiguration.form.get('Contact')?.value.value)

      data.setOrigin(OriginPaymentEnum.MANUALE)

      const model: CtModelConfiguration<any> = CtModelConfiguration.create().setRouteData(WorkOrderPaymentRouteData());

      const pId = this.route.snapshot.paramMap.get("id");
      const id = pId ?? this.parameter?.Oid;

      if (id)
        model.RouteData?.setId(+id)

      this.generalService.putData(model, data)?.then(() => {
        if (this.dialogRef?.close) {
          this.dialogRef?.close();
          window.location.reload();
        }
        else
          this.Router.navigate(['payments'])

      });

    }

  }

  close() {

    if (this.dialogRef?.close) {
      this.dialogRef?.close();
    }
    else
      this.Router.navigate(['payments'])

  }


}

export enum RecipientEnum {

  Amministrator = 1,
  Client = 2,
  IsSelf = 3

}
