import { AfterViewInit, ChangeDetectorRef, Component, TemplateRef, ViewChild } from "@angular/core";
import {
  CtBinaryOperator,
  CtButtonConfiguration,
  CtControlConfiguration,
  CtModelConfiguration,
  CTModelDatatableFilter, CtModelDatatableOperators, CtModelOnFlyCreateService,
  CtModelRouteData,
  CtModelService,
  CtModelType,
  MAT_RAISED_PRIMARY,
  MateriaHintExtensionConfig
} from "@ctsolution/ct-framework";
import { ActivatedRoute } from "@angular/router";
import { CatalogService } from "../catalog.service";
import { CTManagerItem } from "../../../_core/classes/ct-manager-item";
import { ItemType } from "../../../_core/enum/item-type";
import { ItemSupplier } from "../../../_core/classes/item-supplier";
import { ItemSupplierRouteData } from "../../../_core/route-data/item-supplier.route-data";
import { ItemRouteData } from "../../../_core/route-data/item.route-data";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { CatalogCloneItemComponent } from "./catalog-clone-item/catalog-clone-item.component";
import { CatalogCloneItemConfiguration } from "./catalog-clone-item/catalog-clone-item.configuration";
import { CTMGeneralService } from "../../../_core/lib/general.service";
import { CtWebapiGenericResponse } from "@ctsolution/ct-webapi";
import { ChildModuleItemRouteData } from "../../../_core/route-data/child-module-item.route-data";

@Component({
  selector: "app-catalog-edit",
  template: `

    <mat-card>

<mat-card-content>

  <mat-card-title>

  </mat-card-title>

  <div class="ct-model-container">

  <ct-model *ngIf="itemModelConfiguration" [configuration]="itemModelConfiguration"></ct-model>

  </div>

  <mat-card-actions *ngIf="itemModelConfiguration?.form && isModule" align="end">

    <ct-button [configuration]="submitButton"></ct-button>

  </mat-card-actions>

</mat-card-content>

</mat-card>

    <ng-container *ngIf="enableSupplierTable">

      <div class="ct-manager-master-detail-container">

        <h3> {{ 'supplierManagementLabel' | translate }} </h3>

        <ct-model *ngIf="suppliersModelConfiguration" [configuration]="suppliersModelConfiguration"></ct-model>

      </div>

    </ng-container>
    <ng-template #headerActions>

    <ct-button *ngIf="pId" [configuration]="createButton"></ct-button>

</ng-template>`
})
export class CatalogEditComponent implements AfterViewInit {

  @ViewChild("headerActions") headerActions: TemplateRef<any> | null = null;

  itemModelRouteData: CtModelRouteData = ItemRouteData()
    .setModelType(CtModelType.FORM);
  itemModelConfiguration: CtModelConfiguration<CTManagerItem> | null = null;

  supplierModelRouteData: CtModelRouteData = ItemSupplierRouteData()
    .setModelType(CtModelType.DATATABLE);
  suppliersModelConfiguration: CtModelConfiguration<CatalogEditComponent> | null = null;

  pId: string | null = null;
  priceModule: any;
  isModule = false;

  submitButton: CtButtonConfiguration = CtButtonConfiguration
  .create()
  .setLabel("CT_PAGE.CT_FORM.save")
  .setClass('ct-model-submit-button')
  .setAction(() => this.onSubmitModule())
  .setMatherialOptions(MAT_RAISED_PRIMARY);

  createButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("duplicateItem")
    .setIcon("content_copy")
    .setAction(() => this.createModule())
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  constructor(
    private route: ActivatedRoute,
    private catalogService: CatalogService,
    private onFlyCreateHelper: CtModelOnFlyCreateService,
    private cdr: ChangeDetectorRef,
    private dialog: MatLegacyDialog,
    private modelService: CtModelService<any>,
    private generalService : CTMGeneralService
  ) {

    this.setPriceToReturn();

    setTimeout(() => {

      this.setupItemModelConfiguration()

      this.setupSuppliersModelConfiguration();

    }, 500); //TODO: come evitare timeout?


  }

  get enableSupplierTable(): boolean {

    if (!this.itemModelConfiguration) return false;

    const typeFilter = this.itemModelConfiguration.filterValues.constants?.find(flt => flt.Field === 'Type');

    return !!this.itemModelConfiguration.RouteData?.id && typeFilter?.Value === ItemType.Item;

  }

  async setupItemModelConfiguration() {

    this.pId = this.route.snapshot.paramMap.get("id");

    if (this.pId) {
      this.itemModelRouteData.setId(+this.pId);
    }

    const configuration = CtModelConfiguration
      .create<CTManagerItem>()
      .setRouteData(this.itemModelRouteData)


    const typePathValue: string | null = this.route.snapshot.paramMap.get("type");

    if (!typePathValue) return;

    const constantType: ItemType | null = this.catalogService.getCatalogTypeByRouteValue(typePathValue);

    if (constantType) {

      configuration
        .filterValues
        .addConstantFilter(
          CTModelDatatableFilter
            .create()
            .setField('Type')
            .setValue(constantType)
            .setOperatorType(CtBinaryOperator.Equal)
        );

      const isModuleTemplate: boolean = constantType === ItemType.Module;

      const fieldsCustomTemplates: any = [
        { prop: "Type", disabled: true, required: true, visible: false },
        { prop: "Suppliers", visible: false }, // useremo la tabella esterna per i fornitori
        { prop: "Category", visible: !isModuleTemplate },
        { prop: "Brand", visible: !isModuleTemplate },
        {
          prop: "DefaultUnitOfMeasure",
          disabled: !!configuration.RouteData?.id // per un articolo non devo piu poter modificare l'unità di misura una volta settato
        }
      ];

      if (isModuleTemplate) {

        this.isModule = true;

        this
          .route
          .data
          .subscribe(data => {
            data["title"] = "Assemblati";
          });

        if (this.pId) {

          const value =
          {
            prop: "DefaultPrice",
            hint: MateriaHintExtensionConfig
              .create()
              .setMessage(`Clicca qui per applicare prezzo consigliato: ${this.priceModule ?? ""}`)
              .setAction((control: CtControlConfiguration | null) => this.getPriceForModule(control))
          }

          fieldsCustomTemplates.push(value);
        }

        configuration
          .setDefaultActionsEnabled(false)

      }

      configuration
        .setFieldsCustomTemplates(fieldsCustomTemplates);

    }

    this.itemModelConfiguration = configuration;

  }

  onSubmitModule(){

    this.itemModelConfiguration
      ?.form
      ?.markAllAsTouched();

    if (this.itemModelConfiguration?.form?.valid) {

      const value = this.itemModelConfiguration.form.value;

      if(value.DefaultUnitOfMeasure)
      {
        value.DefaultUnitOfMeasure = {Oid: value.DefaultUnitOfMeasure};
      }

      this.modelService
      .putInfos(this.itemModelConfiguration, value)
      ?.subscribe((res : CtWebapiGenericResponse<any>) => {

      const id = this.pId ? +this.pId : res.Result;

      this.generalService.navigateOnCTModelRouter(["catalog","module"], "detail", {
        value : {Oid: id, Name: this.itemModelConfiguration?.form?.get("Name")?.value ?? "assemblato"},
        configuration : this.itemModelConfiguration,
        qpKeys: ["Name"]
      });

    });

  }


}

  setupSuppliersModelConfiguration() {

    const configuration = CtModelConfiguration
      .create<CatalogEditComponent>()
      .setRouteData(this.supplierModelRouteData)
      .setFilterable(false)
      .setFieldsCustomTemplates([{ prop: "Item", disabled: false }])
      .setOnCreate(() => this.openItemSupplierForm())
      .setOnEdit((itemSupplier: ItemSupplier) => this.openItemSupplierForm(itemSupplier.Oid));

    configuration
      .filterValues
      .addConstantFilter(CTModelDatatableFilter
        .create()
        .setField('Item.Oid')
        .setValue(this.itemModelConfiguration?.RouteData?.id)
        .setOperatorType(CtBinaryOperator.Equal));

    this.suppliersModelConfiguration = configuration;


  }

  openItemSupplierForm(Oid: number | null = null) {

    const routeData: CtModelRouteData = CtModelRouteData
      .create()
      .setController(ItemSupplierRouteData().controller)
      .setModelType(CtModelType.FORM);

    const configuration: CtModelConfiguration<any> | null = CtModelConfiguration.create()

    if (Oid) {

      routeData
        .setId(Oid);

    }

    if (this.itemModelConfiguration?.DataSource) {

      configuration
        .filterValues
        .addConstantFilter(
          CTModelDatatableFilter
            .create()
            .setField('Item')
            .setValue(this.itemModelConfiguration?.DataSource)
            .setOperatorType(CtBinaryOperator.Equal)
        )

    }

    configuration
      .setRouteData(routeData)
      .setFieldsCustomTemplates([{ prop: 'Item', disabled: true }])

    this.onFlyCreateHelper
      .openOnFlyCreate(configuration)
      .afterClosed()
      .subscribe((reload: any) => {

        if (reload) window.location.reload(); // dovrei usare la navigazione sul router, però non sta funzionando correttamente ... per ora facciamo refresh forzato

      });

  }

  ngAfterViewInit(): void {

    const typePathValue: string | null = this.route.snapshot.paramMap.get("type");
    const constantType: ItemType | null = this.catalogService.getCatalogTypeByRouteValue(typePathValue);

    //TODO: Centralizzare

    if (constantType == ItemType.Item) {
      this.route
        .queryParams
        .subscribe(qp => {

          this.route
            .data
            .subscribe(data => {

              data['headerActions'] = this.headerActions;
              data['urls'] = [{ title: '' }];
              this.cdr.detectChanges();


            })

        });
    }

  }

  createModule() {

    const typePathValue: string | null = this.route.snapshot.paramMap.get("type");
    const formValue = this.itemModelConfiguration?.form?.value;
    let codeValue: string | null = null;
    if (formValue) {

      codeValue = formValue.Code;

    }


    if (!this.pId || !typePathValue || !codeValue) return;

    this.dialog.open(CatalogCloneItemComponent, {
      data: {
        configuration:
          CatalogCloneItemConfiguration.create()
            .setContent("cloneItemLabel")
            .setTitle("warning")
            .setItemOid(+this.pId)
            .setCodeValue(codeValue)
      }, maxWidth: '500px', minWidth: '300px'
    })


  }

  getPriceForModule(control: CtControlConfiguration | null) {

    if (!this.pId) return;

    const form = control?.formParent;

    if (!form) return;

    form.patchValue({
      DefaultPrice: this.priceModule
    });

  }

  private setPriceToReturn() {

    this.pId = this.route.snapshot.paramMap.get("id");

    if (!this.pId) return

    const configuration: CtModelConfiguration<any> = CtModelConfiguration
      .create()
      .setRouteData(ChildModuleItemRouteData());

    const filters = CtModelDatatableOperators
      .create()
      .setFilters([
        CTModelDatatableFilter
          .create()
          .setField('Module.Oid')
          .setValue(this.pId)
          .setOperatorType(CtBinaryOperator.Equal)
      ]);

    this.modelService.getList(configuration, filters).subscribe((response: CtWebapiGenericResponse<any>) => {

      const res = response.Result?.DataSource;
      this.priceModule = 0;

      if (res) {

        res.forEach((elm: { Item: { DefaultPrice: number; }; Qty: number; }) => {

          if (elm.Item) {
            this.priceModule = this.priceModule + elm.Item.DefaultPrice * elm.Qty;
          }

        });

      }

    })

  }

}
