import {Component, Input} from '@angular/core';
import {CTWorkOrder} from "../../../../../../_core/classes/ct-work-order";
import {CtWorkOrderInformationContent} from "../../../../../../_core/classes/ct-work-order.information-content";
import {CTWorkOrderRowUnionType} from "../../../../../../_core/classes/ct-work-order.row-union";
import {WorkOrderService} from "../../../../work-order.service";
import {MovementManagerConfiguration} from "../movement-manager.configuration";
import {MovementManagerAddItemService} from "../movement-manager-add-item/movement-manager-add-item.service";
import {WorkOrderRowState} from "../../../../../../_core/enum/work-order-row-state";
import {CtButtonConfiguration, MAT_RAISED_PRIMARY} from "@ctsolution/ct-framework";
import {ModuleCreateService} from "../../../../../catalog/catalog-detail/module-create/module-create.service";
import {TransferItemsRequestParameter} from "../../../../../../_core/classes/transfer-items-request-parameter";
import {Table} from "primeng/table";
import { CTConfigurationModel } from 'projects/ct-manager/src/app/components/company/company-edit/company-edit.component';
import { CtManagerService } from 'projects/ct-manager/src/app/_core/lib/ct-manager.service';

@Component({
  selector: 'app-movement-manager-rows',
  templateUrl: './movement-manager-rows.component.html',
  styleUrls: ['./movement-manager-rows.component.scss'],
  providers: [ModuleCreateService]
})
export class MovementManagerRowsComponent {

  items: Array<CTWorkOrderRowUnionType> = new Array<CTWorkOrderRowUnionType>();
  @Input() configuration: MovementManagerConfiguration | null = null;
  colsWorkOrderRowsConfiguration : CTConfigurationModel = CTConfigurationModel.create();

  createButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("generateMovementsLabel")
    .setAction(() => this.createOrders())
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  filterPanelEnabled: boolean = false;

  cols = [
    {field: 'Code', header: 'ItemCode'}, // width: 200px
    {field: 'Description', header: 'ItemDescription'},
    {field: 'Quantity', header: 'Quantity'}, // width: 100px
    {field: 'Amount', header: 'Amount'}, // width: 100px
    {field: 'Discount', header: 'Discount'}, // width: 100px
    {field: 'VAT', header: 'IVA'} // width: 100px
  ]

  get hasHandlingManagementButtonEnabled() {

    return this.items.filter(elm => elm.State !== WorkOrderRowState.Closed).length && !this.configuration?.IsQuotation;

  }

  constructor(
    private workOrderService: WorkOrderService,
    private moduleCreateService: ModuleCreateService,
    private movementManagerAddItemService: MovementManagerAddItemService,
    private managerService : CtManagerService) {
  }

  addItem(value: CTWorkOrderRowUnionType) {

    this.items = [...this.items, value];

  }

  toggleFilters(table: Table) {

    this.filterPanelEnabled = !this.filterPanelEnabled;

    if (!this.filterPanelEnabled) {

      table.clear();

    }

  }

  async onRowEdit(idx: number, row: CTWorkOrderRowUnionType) {

    const item = await this.movementManagerAddItemService.manageRow(this.configuration, row);

    if (item) this.items[idx] = item;

  }

  onRowDelete(idx: number, row: CTWorkOrderRowUnionType) {

    const splice = () => {

      this.items.splice(idx, 1);
      this.items = [...this.items];

    }

    if (!row.Oid) {

      splice();
      return;

    }

    this.workOrderService
      .deleteRow(row)
      .then(obs => obs?.subscribe(() => splice()))

  }

  async setup(model: CTWorkOrder<CtWorkOrderInformationContent> | null) {

    if (!model) return;

    this.items = model.Rows ?? [];

    await this.managerService.getComponentOverride("workOrder", "colsWorkOrderRowsConfiguration", this.colsWorkOrderRowsConfiguration).then((component) => {
      return;
    });

    if(this.colsWorkOrderRowsConfiguration.cols.length > 0) {

      this.cols = this.cols.filter(c => this.colsWorkOrderRowsConfiguration.cols.includes(c.field));

    }

  }

  isCol(value : string) : boolean {

    return this.cols.find(c => c.field === value)? true : false

  }

  createOrders() {

    if (!this.configuration?.WorkOrder?.Oid) return;

    const parameter: TransferItemsRequestParameter = TransferItemsRequestParameter
      .create()
      .setWorkOrderOid(this.configuration?.WorkOrder?.Oid);

    this.moduleCreateService
      .open(parameter)
      ?.afterClosed()
      .subscribe(result => {

        if (result) window.location.reload(); // dovrei usare la navigazione sul router, però non sta funzionando correttamente ... per ora facciamo refresh forzato

      });

  }

  protected readonly WorkOrderRowState = WorkOrderRowState;

}
