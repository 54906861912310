import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CtBinaryOperator, CtButtonConfiguration, CtControlService, CtControlTypes, CtFormConfiguration, CtGenericDialogConfiguration, CTModelDatatableFilter, CtModelDatatableOperators, CtModelService, CtSelectControlOptions, CtSortOrderDescriptorParameter, CtThemeTypes, MAT_RAISED_PRIMARY, SnackbarService } from '@ctsolution/ct-framework';
import { CtWebapiGenericResponse, CtWebapiService, DataRequest, MethodEnum } from '@ctsolution/ct-webapi';
import { WorkOrderPayment } from 'projects/ct-manager/src/app/_core/classes/work-order-payments';
import { CTMGeneralService } from 'projects/ct-manager/src/app/_core/lib/general.service';
import { WorkOrderPaymentRouteData } from 'projects/ct-manager/src/app/_core/route-data/work-order-payment.route-data';
import { MassiveChangesService } from '../../massive-changes.service';
import { DatePipe } from '@angular/common';
import { WorkOrderRouteData } from 'projects/ct-manager/src/app/_core/route-data/work-order.route-data';

@Component({
  selector: 'app-massive-payments',
  templateUrl: './massive-payments.component.html',
  styleUrls: ['./massive-payments.component.scss']
})
export class MassivePaymentsComponent implements OnInit {

  private contactControl = this.generalService
  .getLookupControlDefaultConfiguration({
    name: 'Contact',
    controller: 'Contact',
    key: 'Name',
    action: 'Lookup',
    required: true,
  })
  .setFlexSpacePercentage(50)
  .setLabel('Amministratore')
  .configureOptions<CtSelectControlOptions>(options => {

    if (!options) options = CtSelectControlOptions.create();

    options
      .setNullValueEnabled(true)
      .setLookupFilter((value) => {

        const adminFilter = CTModelDatatableFilter.create().setField("Type.Code").setOperatorType(CtBinaryOperator.Equal).setValue("ADMIN");

        const filters: CTModelDatatableFilter[] = [adminFilter];

        if (value) {

          filters
          .push(
            CTModelDatatableFilter
              .create()
              .setField("Name")
              .setValue(value)
              .setOperatorType(CtBinaryOperator.Contains)
          );
        }

        const operators = CtModelDatatableOperators
          .create()
          .setFilters(filters);

        operators
          .Pagination
          ?.setSortOrders(new Array<CtSortOrderDescriptorParameter>(CtSortOrderDescriptorParameter.create('Name')))

        return operators;

      })

  });

  private dateControl = this.ctControlService.getDefaultConfiguration({
    name: 'Date',
    label: 'DateMassive',
    type: CtControlTypes.DATE,
    required: true
  })
  .setFlexSpacePercentage(100)

  private offerTypeLookup = this.generalService
  .getLookupControlDefaultConfiguration({
    name: 'Utility',
    controller: 'OfferType',
    key: 'Name',
    action: 'Lookup',
    required: false
    })
  .setFlexSpacePercentage(50)

  searchButton : CtButtonConfiguration = CtButtonConfiguration.create()
  .setAction(() => this.searchPayments())
  .setLabel("Search")
  .setMatherialOptions(MAT_RAISED_PRIMARY)
  .setClass("button-cntr");

  submitButton : CtButtonConfiguration = CtButtonConfiguration.create()
  .setAction(() => this.submit())
  .setLabel("Submit")
  .setMatherialOptions(MAT_RAISED_PRIMARY)
  .setClass("button-cntr");

  formConfiguration: CtFormConfiguration | null = null;
  submitFormConfiguration: CtFormConfiguration | null = null;

  elements: WorkOrderPayment[] | null = null;
  selectedElements = [];



  constructor(private snackBar : SnackbarService,private datePipe : DatePipe, private generalService : CTMGeneralService, private fb: FormBuilder, private ctControlService : CtControlService, private cdr : ChangeDetectorRef, private _webapi : CtWebapiService,private massiveChangesService : MassiveChangesService) {

    this.formConfiguration = CtFormConfiguration
    .create(this.fb.group({}))
    .setTheme(CtThemeTypes.MATERIAL)
    .setControls([this.contactControl,this.offerTypeLookup]);

  }

  ngOnInit() {

  }

  private searchPayments(){

    this.formConfiguration?.form?.markAllAsTouched();

    if(this.formConfiguration?.form?.valid == false) return;

    const param = {

      ContactOid : this.formConfiguration?.form?.get('Contact')?.value.value,
      OfferTypeOid : this.offerTypeLookup.control?.value?.value ?? null

    }

    this._webapi.post(getWorkOrdersForMassiveEditPayment(param)).subscribe((response: CtWebapiGenericResponse<any>) => {

      this.elements = [];
      this.elements = [...response.Result];
      this.setupSubmitForm();
      this.cdr.detectChanges();

    });


  }


  private setupSubmitForm() {

    this.submitFormConfiguration = CtFormConfiguration
    .create(this.fb.group({}))
    .setTheme(CtThemeTypes.MATERIAL)
    .setControls([this.dateControl]);

  }

  private submit(){

    this.selectedElements;
    this.submitFormConfiguration?.form?.markAllAsTouched();

    if(this.selectedElements.length < 1) this.snackBar.open("Seleziona almeno un elemento!");

    if(this.submitFormConfiguration?.form?.valid == false || this.selectedElements.length < 1) return;

    const WorkOrderPaymentsOid: number[] = this.selectedElements.map((element : any) => element.Oid);
    const paymentDate = this.submitFormConfiguration?.form?.get('Date')?.value;

    const formattedDate = this.datePipe.transform(paymentDate, 'dd/MM/yyyy');


    const dialogConfig = CtGenericDialogConfiguration.create()
    .setTitle("Attenzione")
    .setContent(`Stai per modificare lo Stato in 'Pagato' per ${WorkOrderPaymentsOid.length} Contratto/i. Data di pagamento selezionata ${formattedDate}`)
    .setSubmitLabel("Conferma")
    .setCancelLabel("Annulla")

    this.massiveChangesService.open(dialogConfig).afterClosed().subscribe((res : any) => {

        if(res)
        {
          this.massivePaymentEditRequest(new MassivePaymentEditParameter(WorkOrderPaymentsOid, paymentDate)).subscribe(res =>{
            this.searchPayments();
            this.snackBar.onSuccess();
          });
        }

    });

  }

  massivePaymentEditRequest(parameter: MassivePaymentEditParameter) {

    const CONTROLLER_REQUEST = (): DataRequest => new DataRequest().setController([WorkOrderPaymentRouteData().controller!]);

    const request: DataRequest = CONTROLLER_REQUEST()
      .setAction("MassivePaymentEdit")
      .setMethod(MethodEnum.POST)
      .setQueryParams({
        hideSpinner: true
      })
      .setBody(parameter);

    return this._webapi
      .request(request);

  }

}

export class MassivePaymentEditParameter {

  WorkOrderPaymentsOid?: number[];
  PaymentDate?: Date

  constructor(workOrderPaymentsOid : number[], paymentDate: Date) {

    this.PaymentDate = paymentDate;
    this.WorkOrderPaymentsOid = workOrderPaymentsOid;

  }

}

export const getWorkOrdersForMassiveEditPayment : (param : any) => DataRequest = (param : any) => new DataRequest()
.setController([WorkOrderRouteData().controller!])
.setAction('GetWorkOrdersForMassiveEditPayment')
.setMethod(MethodEnum.POST)
.setBody(param);
