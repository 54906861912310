import { Component, Input } from "@angular/core";
import {
  CtBinaryOperator,
  CtModelConfiguration, CTModelDatatableFilter,
  CtModelDatatableOperators,
  CtModelService
} from "@ctsolution/ct-framework";
import { DEFAULT_DATATABLE_CONFIGURATION } from "../../../../_core/constants";
import { LocationStockRouteData } from "../../../../_core/route-data/location-stock.route-data";
import { ChildModuleItemRouteData } from "projects/ct-manager/src/app/_core/route-data/child-module-item.route-data";
import { firstValueFrom } from "rxjs";

@Component({
  selector: "app-module-stocks",
  template: `
    <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>`
})
export class ModuleStocksComponent {

  @Input() referenceId: string | null = null;
  configuration: CtModelConfiguration<ModuleStocksComponent> | null = null;

  constructor(private modelService: CtModelService<any>) { }

  async ngAfterViewInit() {

    if (!this.referenceId) return;

    const configurationItem = CtModelConfiguration
      .create<any>()
      .setRouteData(ChildModuleItemRouteData())

    const body: CtModelDatatableOperators = CtModelDatatableOperators
      .create()
      .setFilters([CTModelDatatableFilter
        .create()
        .setField('Module.Oid')
        .setValue(this.referenceId)
        .setOperatorType(CtBinaryOperator.Equal)]);

        const filters: number[] = [+this.referenceId];
        
        const result = await firstValueFrom(this.modelService.getList(configurationItem, body));
        
        if (result.Result?.DataSource) {
          result.Result?.DataSource.forEach((element: any) => {
            filters.push(element.Item?.Oid);
          });
        }
        
        const configuration = CtModelConfiguration
          .create<ModuleStocksComponent>()
          .setRouteData(LocationStockRouteData())
          .setCTDatatableConfiguration(DEFAULT_DATATABLE_CONFIGURATION())
          .setFieldsCustomTemplates([{ prop: "Item", visible: false }]);
        
          configuration
          .filterValues
          .addConstantFilter(

            CTModelDatatableFilter
            .create()
            .setField('Item.Oid==?')
            .setValue(filters)
            .setOperatorType(CtBinaryOperator.BitwiseOr)

          );

    this.configuration = configuration;

  }

}
