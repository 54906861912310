import { Component, Input } from "@angular/core";
import { CtBinaryOperator, CTGeneralService, CtModelConfiguration, CTModelDatatableFilter, CtModelOperator } from "@ctsolution/ct-framework";
import { DEFAULT_DATATABLE_CONFIGURATION } from "../../../../_core/constants";
import { HandlingRouteData } from "../../../../_core/route-data/handling.route-data";
import { CTMGeneralService } from "projects/ct-manager/src/app/_core/lib/general.service";
import { ItemRouteData } from "projects/ct-manager/src/app/_core/route-data/item.route-data";
import { HandlingOriginEnum } from "projects/ct-manager/src/app/_core/enum/handling-origin.enum";
import { Router } from "@angular/router";

@Component({
  selector: "app-module-handlings",
  template: `
    <ct-model *ngIf="configuration" [configuration]="configuration"></ct-model>`
})
export class ModuleHandlingsComponent {

  @Input() referenceId: string | null = null;
  configuration: CtModelConfiguration<ModuleHandlingsComponent> | null = null;

  constructor(private generalService: CTMGeneralService, private router : Router) { }

  async ngAfterViewInit() {

    if (!this.referenceId) return;

    const oid = +this.referenceId

    const groupCode = await new Promise((resolve, reject) => {

      this.generalService.getInfos({
        controller: ItemRouteData().controller!,
        oid: oid
      }).subscribe(data => {

        if (data.Result)
          resolve(data.Result.DataSource?.GroupCode)

      },
        (error) => reject(error)
      );
    });


    if (!groupCode) return;

    const configuration = CtModelConfiguration
      .create<ModuleHandlingsComponent>()
      .setRouteData(HandlingRouteData())
      .setOnDetail(handling => {

        this.router.navigate(["/", "handling", handling.HandlingOrigin === HandlingOriginEnum.Supplier ? 'order' : 'movement', "edit", handling.Oid]);

      })
      .setCTDatatableConfiguration(DEFAULT_DATATABLE_CONFIGURATION())

      configuration
      .initialized()
      .subscribe(() => configuration?.setOperations([CtModelOperator.Detail]));

    configuration
      .filterValues
      .addConstantFilter(
        CTModelDatatableFilter
          .create()
          .setField("ItemHandlingOid")
          .setValue(this.referenceId)
          .setOperatorType(CtBinaryOperator.Equal)
      )
      .addConstantFilter(
        CTModelDatatableFilter
          .create()
          .setField("GroupCode")
          .setValue(groupCode)
          .setOperatorType(CtBinaryOperator.Equal)
      );

    this.configuration = configuration;

  }

}
