import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CatalogEditComponent} from "./catalog-edit.component";
import {CtButtonModule, CtFrameworkModule, CtModelModule} from "@ctsolution/ct-framework";
import {MatLegacyListModule as MatListModule} from "@angular/material/legacy-list";
import { CatalogCloneItemModule } from './catalog-clone-item/catalog-clone-item.module';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [
    CatalogEditComponent
  ],
  imports: [
    CommonModule,
    CtModelModule,
    MatListModule,
    CtFrameworkModule,
    CtButtonModule,
    CatalogCloneItemModule,
    MatLegacyDialogModule,
    MatCardModule
  ]
})
export class CatalogEditModule {
}
