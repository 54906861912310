import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CtBinaryOperator, CtButtonConfiguration, CtControlService, CtControlTypes, CtFormConfiguration, CtGenericDialogConfiguration, CtModelConfiguration, CTModelDatatableFilter, CtModelDatatableOperators, CtModelService, CtSelectControlOptions, CtSelectControlValue, CtSortOrderDescriptorParameter, CtThemeTypes, MAT_RAISED_PRIMARY, SnackbarService } from '@ctsolution/ct-framework';
import { CtWebapiGenericResponse, CtWebapiService, DataRequest, MethodEnum } from '@ctsolution/ct-webapi';
import { WorkOrderPayment } from 'projects/ct-manager/src/app/_core/classes/work-order-payments';
import { CTMGeneralService } from 'projects/ct-manager/src/app/_core/lib/general.service';
import { MassiveChangesService } from '../../massive-changes.service';
import { DatePipe } from '@angular/common';
import { getWorkOrderFilterList, MassiveChangeOffer } from '../massive-offert/massive-offert.component';
import { WorkOrderDocumentSendTypeVO } from 'projects/ct-manager/src/app/_core/enum/document-send-type.enum';
import { PaymentMode } from 'projects/ct-manager/src/app/_core/enum/payment-mode';
import { WorkOrderRouteData } from 'projects/ct-manager/src/app/_core/route-data/work-order.route-data';
import { InvoicePeriodicityEnum } from 'projects/ct-manager/src/app/_core/enum/invoice-periodicity.enum';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-massive-billing',
  templateUrl: './massive-billing.component.html',
  styleUrls: ['./massive-billing.component.scss']
})
export class MassiveBillingComponent implements OnInit {

  private contactControl = this.generalService
    .getLookupControlDefaultConfiguration({
      name: 'Contact',
      controller: 'Contact',
      key: 'Name',
      action: 'Lookup',
      required: true,
    })
    .setFlexSpacePercentage(50)
    .setLabel('Amministratore')
    .configureOptions<CtSelectControlOptions>(options => {

      if (!options) options = CtSelectControlOptions.create();

      options
        .setNullValueEnabled(true)
        .setLookupFilter((value) => {

          const adminFilter = CTModelDatatableFilter.create().setField("Type.Code").setOperatorType(CtBinaryOperator.Equal).setValue("ADMIN");

          const filters: CTModelDatatableFilter[] = [adminFilter];

          if (value) {

            filters
            .push(
              CTModelDatatableFilter
                .create()
                .setField("Name")
                .setValue(value)
                .setOperatorType(CtBinaryOperator.Contains)
            );
          }

          const operators = CtModelDatatableOperators
            .create()
            .setFilters(filters);

          operators
            .Pagination
            ?.setSortOrders(new Array<CtSortOrderDescriptorParameter>(CtSortOrderDescriptorParameter.create('Name')))

          return operators;

        })

    });

  private offerTypeLookup = this.generalService
    .getLookupControlDefaultConfiguration({
      name: 'Utility',
      controller: 'OfferType',
      key: 'Name',
      action: 'Lookup',
      required: false
    })
    .setFlexSpacePercentage(50)

  private DocumentSendType = this.ctControlService
    .getDefaultConfiguration({
      name: 'DocumentSendType',
      type: CtControlTypes.ENUMERABLE,
      required: false
    })
    .configureOptions<CtSelectControlOptions>(options => {

      if (!options) options = CtSelectControlOptions.create();

      options
        .setNullValueEnabled(true)
    })
    .setValueOptions([
      CtSelectControlValue.create().setLabel('PostMail').setValue(WorkOrderDocumentSendTypeVO.PostMail),
      CtSelectControlValue.create().setLabel('Mail').setValue(WorkOrderDocumentSendTypeVO.Email),
      CtSelectControlValue.create().setLabel('PostMailAndEmail').setValue(WorkOrderDocumentSendTypeVO.PostMailAndEmail),

    ])
    .setFlexSpacePercentage(33)

  private InvoicePeriodicity = this.ctControlService
    .getDefaultConfiguration({
      name: 'InvoicePeriodicity',
      type: CtControlTypes.ENUMERABLE,
      flexSpacePercentage: 33,
      required: false
    })
    .configureOptions<CtSelectControlOptions>(options => {

      if (!options) options = CtSelectControlOptions.create();

      options
        .setNullValueEnabled(true)
    })
    .setValueOptions([
      CtSelectControlValue.create().setLabel('monthly').setValue(InvoicePeriodicityEnum.monthly),
      CtSelectControlValue.create().setLabel('bimonthly').setValue(InvoicePeriodicityEnum.bimonthly),
    ])

  private PaymentMode = this.ctControlService
    .getDefaultConfiguration<number | null>({
      name: 'PaymentMode',
      type: CtControlTypes.ENUMERABLE,
      required: false
    })
    .setLabel('Modalità di pagamento')
    .setValueOptions([
      CtSelectControlValue
        .create()
        .setLabel('RID')
        .setValue(PaymentMode.RID),
      CtSelectControlValue
        .create()
        .setLabel('Bonifico Bancario')
        .setValue(PaymentMode.BonificoBancario)
    ])
    .setNullValueEnabled(true)
    .setFlexSpacePercentage(33);

  searchButton: CtButtonConfiguration = CtButtonConfiguration.create()
    .setAction(() => this.searchWorkOrder())
    .setLabel("Search")
    .setMatherialOptions(MAT_RAISED_PRIMARY)
    .setClass("button-cntr");

  submitButton: CtButtonConfiguration = CtButtonConfiguration.create()
    .setAction(() => this.submit())
    .setLabel("Submit")
    .setMatherialOptions(MAT_RAISED_PRIMARY)
    .setClass("button-cntr");

  formConfiguration: CtFormConfiguration | null = null;
  submitFormConfiguration: CtFormConfiguration | null = null;

  elements: WorkOrderPayment[] | null = null;
  selectedElements = [];



  constructor(private snackBar: SnackbarService, private translateService : TranslateService, private generalService: CTMGeneralService, private fb: FormBuilder, private ctControlService: CtControlService, private cdr: ChangeDetectorRef, private _webapi: CtWebapiService, private massiveChangesService: MassiveChangesService) {

    this.formConfiguration = CtFormConfiguration
      .create(this.fb.group({}))
      .setTheme(CtThemeTypes.MATERIAL)
      .setControls([this.contactControl, this.offerTypeLookup]);

  }

  ngOnInit() {


  }

  getInvoicePeriodicityLabel(value: number | null): string {

    switch (value) {
      case InvoicePeriodicityEnum.monthly:
        return  this.translateService.instant('monthly');
      case InvoicePeriodicityEnum.bimonthly:
        return this.translateService.instant('bimonthly');
      default:
        return '';

    }
  }

  getWorkOrderDocumentSendTypeLabel(value: number | null): string {
    switch (value) {
      case WorkOrderDocumentSendTypeVO.PostMail:
        return this.translateService.instant('PostMail');
      case WorkOrderDocumentSendTypeVO.Email:
        return this.translateService.instant('Mail');
      case WorkOrderDocumentSendTypeVO.PostMailAndEmail:
        return this.translateService.instant('PostMailAndEmail');
      default:
        return '';
    }
  }

  getPaymentModeLabel(value: number | null): string {
    switch (value) {
      case PaymentMode.RID:
        return this.translateService.instant('RID');
      case PaymentMode.BonificoBancario:
        return this.translateService.instant('Bonifico Bancario');
      default:
        return '';
    }
  }

  private searchWorkOrder() {

    this.formConfiguration?.form?.markAllAsTouched();

    if (this.formConfiguration?.form?.valid == false) return;

    const param = {

      ContactOid: this.formConfiguration?.form?.get('Contact')?.value.value,
      OfferTypeOid: this.offerTypeLookup.control?.value?.value

    }

    this._webapi.post(getWorkOrderFilterList(param)).subscribe((response: CtWebapiGenericResponse<any>) => {

      this.elements = [];
      this.elements = [...response.Result];
      this.setupSubmitForm();
      this.cdr.detectChanges();
    });

  }


  private setupSubmitForm() {

    this.submitFormConfiguration = CtFormConfiguration
      .create(this.fb.group({}))
      .setTheme(CtThemeTypes.MATERIAL)
      .setControls([this.DocumentSendType, this.PaymentMode, this.InvoicePeriodicity]);

  }

  private submit() {

    this.submitFormConfiguration?.form?.markAllAsTouched();

    if (this.selectedElements.length < 1) this.snackBar.open("Seleziona almeno un elemento!");

    if (this.submitFormConfiguration?.form?.valid == false || this.selectedElements.length < 1) return;

    const WorkOrdersOid: number[] = this.selectedElements.map((element: any) => element.Oid);

    const PaymentMode = this.PaymentMode.valueOptions?.find(element => element.value == this.PaymentMode.control?.value)?.label;
    const DocumentSendType = this.DocumentSendType.valueOptions?.find(element => element.value == this.DocumentSendType.control?.value)?.label;
    const InvoicePeriodicity = this.InvoicePeriodicity.valueOptions?.find(element => element.value == this.InvoicePeriodicity.control?.value)?.label;

    const changes: string[] = [];

    if (DocumentSendType) {
      changes.push(`Modalità di spedizione con: ${this.translateService.instant(DocumentSendType)}`);
    }

    if (PaymentMode) {
      changes.push(`Metodo di pagamento con: ${this.translateService.instant(PaymentMode)}`);
    }

    if (InvoicePeriodicity) {
      changes.push(`Periodo di fatturazione con: ${this.translateService.instant(InvoicePeriodicity)}`);
    }


    const dialogConfig = CtGenericDialogConfiguration.create()
      .setTitle("Attenzione")
      .setContent(`Stai per sostituire a n. ${this.selectedElements.length} contratti/o: ${changes.join(', ')}`)
      .setSubmitLabel("Conferma")
      .setCancelLabel("Annulla")

    this.massiveChangesService.open(dialogConfig).afterClosed().subscribe((res: any) => {

      if (res) {
        this._webapi.post(MassiveChangeWorkOrderData({
          WorkOrdersOid: WorkOrdersOid,
          NewDocumentSendType: +this.DocumentSendType.control?.value,
          NewPaymentMode: +this.PaymentMode.control?.value,
          NewInvoicePeriodicity: +this.InvoicePeriodicity.control?.value
        })).subscribe((response: CtWebapiGenericResponse<any>) => {

          this.searchWorkOrder();
          this.snackBar.onSuccess()

        });

      }

    });

  }
}

export const MassiveChangeWorkOrderData: (param: any) => DataRequest = (param: any) => new DataRequest()
  .setController([WorkOrderRouteData().controller!])
  .setAction('MassiveChangeWorkOrderData')
  .setMethod(MethodEnum.POST)
  .setBody(param);
