<div class="ct-manager-master-detail-container payments-massive-cntr">
  <h4>{{ "MassiveBilling" | translate }}</h4>

  <div class="form-container">
    <ct-form [configuration]="formConfiguration"></ct-form>
    <ct-button [configuration]="searchButton"></ct-button>
  </div>

  <ng-container *ngIf="elements">
    <ng-container *ngIf="elements.length; else noEleTemplate">
      <p-table
        [value]="elements"
        [(selection)]="selectedElements"
        dataKey="Oid"
        [paginator]="true"
        [rows]="10"
      >
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 4rem"><p-tableHeaderCheckbox /></th>
            <th>Codice</th>
            <th>Stato</th>
            <th>Cliente</th>
            <th>Modalità di spedizione</th>
            <th>Modalità di pagamento</th>
            <th>Periodicità della fattura</th>
            <th>Data inizio fornitura</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-item>
          <tr>
            <td>
              <p-tableCheckbox [value]="item" />
            </td>
            <td>{{ item.Code }}</td>
            <td>{{ item.State.Name }}</td>
            <td>{{ item.Company?.DisplayName ?? "" }}</td>
            <td>{{ getWorkOrderDocumentSendTypeLabel(item.DocumentSendType) }}</td>
            <td>{{ getPaymentModeLabel(item.PaymentMode) }}</td>
            <td>{{ getInvoicePeriodicityLabel(item.InvoicePeriodicity)}}</td>
            <td>{{ item.InsertDate | date }}</td>
          </tr>
        </ng-template>
      </p-table>

      <div class="form-container">
        <ct-form *ngIf="submitFormConfiguration" [configuration]="submitFormConfiguration"></ct-form>
        <ct-button [configuration]="submitButton"></ct-button>
      </div>

    </ng-container>

    <ng-template #noEleTemplate>
      <h4>Nessun contratto trovato</h4>
    </ng-template>
  </ng-container>
</div>
